.box-detail {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    background-color: white;
    height: 100vh;
}

.title-detail {
    background-color: #F8F7F4;
    width: 100%;
    text-align: center;
}

.title-detail h2{
    margin-bottom: 150px;
    margin-top: unset;
    height: 50px;
}

.talad-image-detail {
    position: absolute;
    top: 7%;
    border-radius: 25px;
    overflow: hidden;
    border: none !important;
}

.content-detail {
    margin-top: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.css-iuml8{
    border: none !important;
}
.talad-image{
 width: 400px !important;
 min-width:300px ;
 height: 266px !important;
 display: flex;
 align-items: center;
 justify-content: center;
 background-color: #333;
}
.carousel-image{
    width: 100% !important;
    height: 200px !important;
}
.box-rating{
    display: flex;
    align-items: center;

}
.bar-chart{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.heat-chart{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media only screen and (max-width: 600px) {
    
    .talad-image-detail {
        position: absolute;
        top: 8%;
        border-radius: 25px;
        overflow: hidden;
    border: none !important;
    }
    .title-detail h2{
        margin-bottom: 150px;
        margin-top: unset;
        height: 50px;
    }
    .content-detail {
        margin-top: 130px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .talad-image{
        width: 300px !important;
        min-width:300px ;
         height: 266px !important;

       }
       .carousel-image{
        width: 100% !important;
        height: 200px !important;
    }
}

.skeleton-loader {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite ease-in-out;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: 0 0;
    }
}