.custom-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.prev-arrow {
    left: -40px;
}

.next-arrow {
    right: -40px;
}

.carousel-item {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.item-content {
    border-radius: 10px;
    height: 200px;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.slick-track {
    display: flex;
    gap: 20px; /* ช่องว่างระหว่างไอเทม */
}
