.Data-display {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
}
.home-compoent{
 overflow-y: hidden;   
}
.promote-talad{
    background-color: #C1E0CD;
    border-radius: 20px;
    padding: 10px;
    font-size: 20px;
    margin-top: 40px;
}
.Paginator {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
}
.align-phone-design img{
    object-fit: cover !important;
    min-height: 250px;
}
.align-phone-design{
    display: flex;
    margin-top: 20px;
    align-items: center;
    gap: 40px;
}
.align-phone-design :nth-child(2){
    margin-bottom: 100px;
}
.text-hightlight{
    font-size: 40px;
    font-weight: normal;
}
.section1{
    background-color: #F8F7F4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 50px;
}
.section2{
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.section3{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #F8F7F4;
}
.section4{
    display: flex;
    background-color: #DDDDDD;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
    flex-direction: column;
    justify-content: center;
}
.highlight-green{
    font-weight: bolder;
    color: #2BAF2B;
}
.features-all{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
}
.features-box{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}
.feature-content{
    border: 1px solid #C2C2C2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 140px;
    -moz-border-radius: 140px;
    -webkit-border-radius: 140px;
    border-radius: 140px;
}
.feature-content-title h2{
    font-weight: lighter;
    text-align: center;
}
.feature-content-title span{
    display: flex;
    flex-wrap: wrap;
    width: 240px;
    height: 250px;
    overflow: hidden;
}
.talads-all{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.controls-table-talads{
    background-color: white;
    border-radius: 30px;
    margin-bottom:50px ;
    display: flex;
    align-items: center;
}
.flex-style{
    display: flex;
}
.icon-style{
    font-size: 40px !important;
}
.social-box{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
}
.button-youtube{
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 30px;
    gap: 20px;
    border-radius: 30px;
    background:red;
    color: white;
    text-transform: none;
    font-size: 18px;
}
.button-tiktok{
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 30px;
    gap: 20px;
    border-radius: 30px;
    background:black;
    color: white;
    text-transform: none;
    font-size: 18px;
}
.button-facebook{
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 30px;
    gap: 20px;
    border-radius: 30px;
    background:#0866FF;
    color: white;
    text-transform: none;
    font-size: 18px;
}
.button-instagram{
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 30px;
    gap: 20px;
    border-radius: 30px;
    background: linear-gradient(45deg, #833AB4, #FD1D1D, #FCAF45);
    color: white;
    text-transform: none;
    font-size: 18px;
    /* &:hover{
      background: linear-gradient(45deg, #833AB4, #FD1D1D, #FCAF45);
    }; */
}
.button-line{
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 30px;
    gap: 20px;
    border-radius: 30px;
    background-color: #00B900;
    color: white;
    text-transform: none;
    font-size: 18px;
    /* &:hover{
      background: linear-gradient(45deg, #833AB4, #FD1D1D, #FCAF45);
    }; */
}
@media only screen and (max-width: 768px) {
    .features-box{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        width: 100%;
        margin: 20px;
    }
}
@media only screen and (max-width: 600px) {
    .align-phone-design :first-child{
        display: none;
    }
    .align-phone-design :last-child{
        display: none;
    }
    .text-hightlight{
        font-size: 30px;
        font-weight: normal;
    }
    .features-box{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        width: 100%;
        margin: 20px;
    }
    .feature-content-title{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .feature-content-title span{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        overflow: hidden;
    }
    .feature-content-title span br{
        display: none !important;
    }

    .button-youtube{
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px 30px;
        gap: 20px;
        border-radius: 30px;
        background:red;
        color: white;
        text-transform: none;
        font-size: 18px;
        justify-content: center;
        width: 100%;
    }
    .button-tiktok{
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px 30px;
        gap: 20px;
        border-radius: 30px;
        background:black;
        color: white;
        text-transform: none;
        font-size: 18px;
        justify-content: center;
        width: 100%;
    }
    .button-facebook{
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px 30px;
        gap: 20px;
        border-radius: 30px;
        background:#0866FF;
        color: white;
        text-transform: none;
        font-size: 18px;
        justify-content: center;
        width: 100%;
    }
    .button-instagram{
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px 30px;
        gap: 20px;
        border-radius: 30px;
        background: linear-gradient(45deg, #833AB4, #FD1D1D, #FCAF45);
        color: white;
        text-transform: none;
        font-size: 18px;
        justify-content: center;
        width: 100%;
    }
    .button-line{
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px 30px;
        gap: 20px;
        border-radius: 30px;
        background-color: #00B900;
        color: white;
        text-transform: none;
        font-size: 18px;
        justify-content: center;
        width: 100%;
    }
  }
